/**
 * This config is for delete scene modal, located in the editor zone
 */
export const DeleteSceneDialogConfig = {
  width: '450px',
  data: {
    title: 'Delete Scene',
    question: 'Are you sure?',
    labelConfirm: 'Yes',
    labelUndo: 'No'
  },
};

export const ArchivedShapeDialogConfig = {
  width: '450px',
  data: {
    title: 'Archived Shape',
    question: 'Are you sure? this shape will be archived, you can restore its from the archived shapes',
    labelConfirm: 'Yes',
    labelUndo: 'No'
  },
};

export const DeleteShapeDialogConfig = {
  width: '450px',
  data: {
    title: 'Delete Shape',
    question: 'Are you sure? if you continue this shape will be deleted, and you cannot restore its',
    labelConfirm: 'Yes',
    labelUndo: 'No'
  },
};

export const RestoreShapeDialogConfig = {
  width: '450px',
  data: {
    title: 'Restore Shape',
    question: 'Are you sure?',
    labelConfirm: 'Yes',
    labelUndo: 'No'
  },
};
