import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ShapeApiService} from '@shape/shape-api.service';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';
import {Shape} from '@lib/models/shape';

@Injectable({
  providedIn: 'root',
})
export class ShapeResolverService implements Resolve<Shape> {
  constructor(private shapeApi: ShapeApiService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Shape> {
    return this.shapeApi.getShape(route.paramMap.get('id')).pipe(first());
  }
}
